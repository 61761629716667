.bgImage {
  background-image: url('../../../public/bgSvg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
}

.container {
  padding: 10px 20px;
}

.title {
  font-weight: 600;
  color: rgb(63, 63, 63);
}
.cardContainer {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card {
  background-color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
