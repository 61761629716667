.container {
  padding: 10px 20px;
}

.logoutBtn {
  margin-top: 10px;
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border-radius: 2px;
  font-size: 12px;
  white-space: nowrap;
}

.logoutBtn:hover {
  background-color: rgb(61, 61, 61);
  transition: ease-in-out 0.3s;
  cursor: pointer;
}
