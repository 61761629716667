header {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  height: 60px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: sticky;
  top: 0;
  z-index: 30;
}

nav {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 100%;
  padding: 20px;
}

nav .logo {
  font-size: 18px;
  font-weight: 600;
  color: rgb(22, 127, 153);
}

nav .logo:hover {
  cursor: pointer;
}
