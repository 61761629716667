.container {
  padding: 20px;
}
.deviceStatus {
  padding: 10px;
  border-radius: 4px;
}

.deviceStatus p {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}
.title {
  font-weight: 600;
  color: rgb(41, 41, 41);
  margin-top: 10px;
  font-size: 14px;
}

.deviceInfoCardsContainer {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.deviceInfoCard {
  width: 50%;
  background-color: white;
  border-radius: 4px;
  padding: 10px 20px;
}
.deviceInfoCard .title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  white-space: nowrap;
}
.deviceInfoCard .value {
  font-weight: 600;
}
.graphSec {
  display: flex;
  gap: 20px;
}

.halfCircle {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: 10px solid gray;
  border-bottom: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btnContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.btnContainer .offBtn {
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 500;
}
.btnContainer .offBtn:hover {
  cursor: pointer;
}
.btnContainer .onBtn {
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 500;
}
.btnContainer .onBtn:hover {
  cursor: pointer;
}

.popup {
  position: fixed;
  inset: 0;
  background-color: rgba(68, 66, 66, 0.432);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #0a78c2;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
