.bottomNav {
  position: fixed;
  bottom: 0;
  z-index: 30;
  background-color: white;
  height: 60px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.bottomNav a:first-child {
  color: rgb(22, 127, 153);
}
