.bgImage {
  background-image: url('../../../public//login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
}
.container {
  padding: 20px;
  margin: 0 auto;
}
.welcome {
  padding-top: 50px;
  font-size: 24px;
  font-weight: 500;
}
.brandName {
  font-size: 54px;
  font-weight: 600;
  color: #0267aa;
}
.brandMsg {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 48px;
}
.inpCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 50px;
}
input {
  padding: 20px;
  width: 100%;
  outline: none;
  border: 2px solid #0267aa;
  border-radius: 50px;
  color: #0267aa;
  font-weight: 500;
  font-size: 16px;
}

input:focus {
  border: 2px solid #00497a;
}
.linkBtnCont {
  text-align: center;
}
.linkBtnCont .fortext {
  font-weight: 600;
  font-size: 14px;
}
.linkBtnCont .fortext:hover {
  cursor: pointer;
  color: #0267aa;
}
.linkBtnCont p {
  font-weight: 600;
  font-size: 14px;
}
.linkBtnCont button {
  margin: 20px 0px;
  padding: 20px 0px;
  background-color: #00497a;
  color: white;
  font-weight: 600;
  border-radius: 50px;
  width: 100%;
}
.linkBtnCont button:hover {
  cursor: pointer;
  background-color: #0267aa;
  transition: ease-in-out 0.3s;
}

p a:hover {
  color: #0267aa;
  cursor: pointer;
}
.term {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #0267aa;
  margin-top: 20px;
  padding-bottom: 50px;
}
